<script>
export default {
  name: "lunch-svg"
}
</script>

<template>
  <div>
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
         y="0px"
         width="18px" height="18px" viewBox="0 0 612 612" style="enable-background:new 0 0 612 612;"
         xml:space="preserve"
    >

	<g id="_x38__39_">
			<path d="M592.875,516.375H19.125C8.568,516.375,0,524.943,0,535.5s8.568,19.125,19.125,19.125h573.75
				c10.557,0,19.125-8.568,19.125-19.125S603.432,516.375,592.875,516.375z M325.125,133.875v-38.25h38.25
				c10.557,0,19.125-8.568,19.125-19.125s-8.568-19.125-19.125-19.125h-114.75c-10.557,0-19.125,8.568-19.125,19.125
				s8.568,19.125,19.125,19.125h38.25c0,0-0.134,39.818,0,38.25C126.837,143.801,0,277.332,0,439.875c0,15.529,0,8.568,0,19.125
				s8.568,19.125,19.125,19.125h573.75c10.557,0,19.125-8.568,19.125-19.125s0-2.391,0-19.125
				C612,277.332,485.163,143.801,325.125,133.875z M38.25,439.875C38.25,292,158.125,172.125,306,172.125
				c147.875,0,267.75,119.875,267.75,267.75H38.25z"/>
	</g>
  </svg>
  </div>
</template>

<style scoped lang="scss">

</style>
