<template>
  <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
            <div class="workers-info-title-za">
                <div class="title">{{$t("message.sanction")}}</div>
            </div>

            <div class="m-3">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="refa__chart">
                            <el-card class="box-card bg-light-primary" :class="mode ? '' : 'text-warning__night'">
                                <i
                                    class="my-icon el-icon-tickets text-primary"
                                ></i>
                                <div class="mobile__izn">
                                  <div class="text-center">
                                    <span class="text-primary">
                                        {{$t("message.total_qty")}}
                                    </span>
                                  </div>
                                  <div class="text-center mt-3">
                                      <b class="text-primary">{{list.length}}</b>
                                  </div>
                                </div>
                            </el-card>
                        </div>
                    </el-col>

                    <el-col :span="24">
                        <div class="scrol-table crmhome__scroll">
                            <table
                                :class="mode ? 'table__myday' : 'table__mynight'"
                                class="
                                    table__layout
                                    table-my-code table-bordered
                                    my-table-card my-clendar
                                "
                            >
                                <thead>
                                    <tr>
                                        <th class="w50p" scope="col">{{$t("message.n")}}</th>
                                        <th scope="col">{{$t("message.date_time")}}</th>
                                        <th scope="col">{{$t("message.amount")}}</th>
                                        <th scope="col">{{$t("message.reason")}}</th>
                                        <th scope="col">{{$t("message.comment")}}</th>
                                        <th scope="col">{{$t("message.responsible")}}</th>
                                        <th scope="col">{{$t("message.file")}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(bonuse, index) in list"
                                    :key="'additional-' + index">
                                        <td>{{ index+1 }}</td>
                                        <td>{{ bonuse.created_at }}</td>
                                        <td>{{bonuse.amount}}
                                          </td>
                                          <td>  {{
                                                    bonuse.reason
                                                      ? bonuse.reason.name: ""
                                                  }}
                                          </td>
                                        <td>
                                          {{
                                            bonuse.comment
                                          }}  
                                        </td>
                                        <th>
                                          <div v-if="bonuse.reason&&bonuse.reason.managers">
                                            <span  v-for="manager in bonuse.reason.managers" :key="manager.id">
                                              {{manager.name}} {{manager.last_name}},
                                            </span>
                                          </div>
                                        </th>
                                        <td>
                                          <span style="cursor:pointer; font-weight:bold; color:#2b99d9" @click="showPenaltyFile(bonuse.id)">{{ $t('message.file')  }}</span> 
                                       </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <el-dialog
            :title="$t('message.file')"
            :visible.sync="dialogShowFiles"
            width="40%"
            :append-to-body="true"
            
            ref="dialogShowFiles"
            >
                <div>
                    <ul v-for="file in files" :key="file.id" class="file-list">
                        <li class="file-list-item">
                           <span @click="downloadFiles(file.id, file.penalty_id, file.name)"><i class="el-icon-document"></i> {{file.name}} <i class="el-icon-download ml-3" style="color: #4fc514"></i></span> 
                        </li>
                    </ul>
                </div>
            </el-dialog>
        </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name:'additional-work',
  props: {
      staff_id: {
        type: Number,
      },
    },

  data() {
    return {
      additionals:{},
      dialogShowFiles: false,
    };
  },
  mounted() {
    this.table();
  },
  computed: {
    ...mapGetters({
      list: "profile/staff_penalties",
      files: "penalty/files",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "profile/getPenalties",
      getFiles: "penalty/getFiles",
            // removeFile: "penalty/removeFile",
      downloadFile: "penalty/downloadFile",
    }),
      showPenaltyFile(id){
            this.getFiles(id).then((res)=>{
                this.files = res.data.result.data.files;
            });
            this.dialogShowFiles =true;
        },
      downloadFiles(id, penalty_id, name)
      {
          var data ={
              'id':id,
              'penalty_id':penalty_id,
          };
          return this.downloadFile(data).then((response) => {
                  let blob = new Blob([response.data])
                  // console.log(response.data);
                      let link = document.createElement('a')
                      link.href = window.URL.createObjectURL(blob)
                      link.download = name
                      link.click()
            });
      },
     table(){
      const query = { staff_id: this.staff_id};
      this.updateList(query).then((res) => {
        this.penalties = res.data.result.data.penalties;
      });
    },
  }
}
</script>
<style lang="scss">
    .file-list{
        .file-list-item{
            margin-bottom: 5px;
            span{
                font-weight: bold;
                font-size: 16px;
                color: #2b99d9;
                display: flex;
                align-items: center;
                gap: 5px;
                i{
                    font-weight: bold !important;
                    font-size: 16px !important;
                }
             //   color: rgb(93, 198, 255);
            }
            
        }
    }
</style>

