<template>
  <div class="calendar">
    <div class="staff_monthly_performance">
      <div class="schedule_actual_monthly_performance"></div>
      <div class="total_work_time_compare_block">
        Отработано:
        <span
          class="total_work_time_value"
          :class="{
            'total_work_time_completed': monthlyHoursNormCompleted,
            'total_work_time_not_completed': !monthlyHoursNormCompleted,
          }"
        >
          {{ monthlyTotalActualHour }}
        </span> /
        <span class="total_work_time_value">
          {{ monthlyTotalScheduleHour }}
        </span>
      </div>
    </div>
    <table class="calendar_table">
      <thead>
      <tr class="calendar_header_tr">
        <th class="calendar_th" v-for="day in daysOfWeek" :key="day">{{ day }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(week, weekIndex) in calendarRows" :key="weekIndex">
        <td  v-for="(dayData, dayIndex) in week" :key="dayIndex" class="calendar_td" @dblclick="showStaffAttendanceInfo(dayData)">
          <crm-new-calendar-day
            v-if="dayData"
            :dayData="dayData"
          />
        </td>
      </tr>
      </tbody>
    </table>
    <div class="calendar_legend">
    </div>
    <el-drawer
      title="I'm outer Drawer"
      :visible.sync="showStaffAttendance"
      size="55%"
      :with-header="false"
      :with-footer="false"
      ref="show-tms"
      @opened="drawerStaffAttendanceOpened('show-staff-attendance')"
    >
      <div>
        <crm-new-calendar-show-staff-attendance
          :drawerData="drawerData"
          @closeStaffAttendanceModal="closeStaffAttendanceModal"
          ref="show-staff-attendance"
        />
      </div>
    </el-drawer>
  </div>
</template>
<script>
import CrmNewCalendarDay from "./crm-new-calendar-day.vue";
import {mapActions, mapGetters} from "vuex";
import CrmNewCalendarShowStaffAttendance from "./crm-new-calendar-show-staff-attendance.vue";

export default {
  data() {
    return {
      drawerData: {},
      selected: null,
      showStaffAttendance: false,
      dayData: []
    }
  },
  methods: {
    ...mapActions({
      getNewCalendarData: "staffCalendar/index",
      recalculateStaffMonth: "staffCalendar/recalculateStaffMonth",
    }),
    showStaffAttendanceInfo(dayData) {
      this.drawerData = {
        staff_id: dayData.staff_id,
        date: dayData.date
      };
      this.showStaffAttendance = true;
    },
    closeStaffAttendanceModal(val) {
      this.showStaffAttendance = val;
    },
    drawerStaffAttendanceOpened(ref) {
      console.log('opened')
      if (this.$refs[ref] && typeof this.$refs[ref].opened === 'function') {
        this.$refs[ref].opened();
      }
    },
    chunkArray(array, size) {
      let chunkedArray = [];
      for (let i = 0; i < array.length; i += size) {
        chunkedArray.push(array.slice(i, i + size));
      }
      return chunkedArray;
    },
    async fetchCalendarData() {
      const params = {staff_id: this.staff_id, date: this.select_date};
      await this.getNewCalendarData(params).then((res) => {
        this.dayData = res;
      }).catch((err) => {
        console.log('printErr', err);
      });
    },
    async recalculateMonth() {
      let success = false;
      await this.recalculateStaffMonth({staff_id: this.staff_id, date: this.select_date})
      .then(res => {
        success = true;
        console.log('usccc', res);
      })
      .then(err => {
        console.log('errr', err);

      });
      await this.fetchCalendarData();
      if(success){
        this.$notify({
          title: "Успешно",
          type: "success",
          offset: 7,
          message: "Ежемесячные данные по персоналу успешно пересчитаны!",
        });
      }
      
    },
    parseTimeFromDecimalToHm(timeInDecimalHm) {
      const hours = Math.floor(timeInDecimalHm);
      const minutesDecimal = (timeInDecimalHm - hours) * 60;
      const minutes = Math.round(minutesDecimal);
      const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;
      return hours + ":" + paddedMinutes;
    }
  },
  props: {
    staff_id: {
      type: Number,
    },
    select_date: {
      type: String
    },
    daysOfWeek: {
      type: Array,
      default: () => ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']
    }
  },
  components: {
    CrmNewCalendarShowStaffAttendance,
    CrmNewCalendarDay,
  },
  mounted() {
    this.fetchCalendarData();
  },
  watch: {
    select_date: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.fetchCalendarData();
        }
      },
      deep: true,
      immediate: true,
    }
  },
  computed: {
    monthlyHoursNormCompleted() {
      if (this.dayData.month_performance) {
        const actual = parseFloat(this.dayData.month_performance.total_act_w_month_common || 0);
        const schedule = parseFloat(this.dayData.month_performance.total_sch_w_month_common || 0);
        if (actual > schedule) {
          return true;
        }
      }
    },
    monthlyTotalScheduleHour() {
      if (this.dayData.month_performance) {
        return this.parseTimeFromDecimalToHm(this.dayData.month_performance.total_sch_w_month_common)
      }
    },
    monthlyTotalActualHour() {
      if (this.dayData.month_performance) {
        return this.parseTimeFromDecimalToHm(this.dayData.month_performance.total_act_w_month_common)
      }
    },
    calendarOffset() {
      return this.dayData.month_first_day_offset;
    },
    daysInMonth() {
      return this.dayData.days_in_month;
    },
    calendarRows() {
      let days = [];

      for (let i = 0; i < this.calendarOffset; i++) {
        days.push(null);
      }

      for (let i = 0; i < this.daysInMonth; i++) {
        days.push(this.dayData.month_schedule_and_actual[i]);
      }

      while (days.length % 7 !== 0) {
        days.push(null);
      }

      return this.chunkArray(days, 7);
    },
    ...mapGetters({
      newCalendarData: "staffCalendar/list"
    }),
  },
}
</script>
<style lang="css">
.calendar {
  box-sizing: content-box;
  width: 100%;
}

.staff_monthly_performance {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.3rem 0;
  border-top: solid 1px #d8d8d8;
}

.calendar_td {
  overflow: visible;
  border-collapse: collapse;
  border: solid 1px #d8d8d8;
  padding: 0;
  width: calc(100% / 7);
  min-height: 100px;
}

.calendar_header_tr {
  height: 40px;
}

.calendar_th {
  color: #656565;
  font-weight: 600;
  border-bottom: none;
  width: calc(100% / 7);;
  border-bottom: none;
}

.calendar_table {
  overflow: visible;
  width: 100%;
  height: 70vh;
  align-self: center;
}

.total_work_time_compare_block {
  color: #656565;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 0.9rem;
}

.total_work_time_completed {
  color: #6aad45;
  font-weight: 900;
}

.total_work_time_not_completed {
  color: #ea913b;
  font-weight: 900;
}

.total_work_time_value {
  margin: 0 8px;
}
</style>
